/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import './App.css';
import axiosClient from './api/axiosClient';
import LoadingGlobal from './components/Loading/Loader';
// import ToastGlobal from './components/ToastGlobal';
import Router from './routes/Router';
import { useEffect } from "react";
import * as homePageApi from './api/homePage/homePageApi'
import useResetData from "./hook/useResetData";
// import "antd/dist/reset.css"
function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const { resetLoginOut } = useResetData()

  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    if (ketqua?.success) {
      var pathname = []
      if (ketqua.data.navigation.SOC === 1) {
        await pathname.push('/Soccer_V1')
      }
      if (ketqua.data.navigation.NBA === 1) {
        await pathname.push('/NBA')
      }
      if (ketqua.data.navigation.NFL === 1) {
        await pathname.push('/NFL')
      }
      if (ketqua.data.navigation.MLB === 1) {
        await pathname.push('/MLB')
      }
      if (ketqua.data.navigation.NCAAF === 1) {
        await pathname.push('/NCAAF')
      }
      if (pathname.length === 0) {
        resetLoginOut()
        window.location.href = "/otp-confirm"
      } else {
        if (!pathname.includes(location.pathname) && location.pathname !== "/sleep-mode") {
          window.location.href = pathname[0]

        }
      }
    }
  }

  useEffect(() => {
    viewSettings()
  }, [location.pathname])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentPath = location.pathname;
  axiosClient.interceptors.response.use(
    (response) => response,
    // (response) => response,
    (error) => {
      if ((error.response) && (error.response.status === 403 || error.response.status === 401)) {
        resetLoginOut()
        navigate("/otp-confirm")
        return error?.response?.data;
      }
    }
  );

  return (
    <>
      {/* <ToastGlobal /> */}
      <LoadingGlobal />
      <Router />
    </>
  );
}

export default App;
