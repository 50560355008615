/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import './styles.css'
import CaculateOTP from '../page/homepage/components/TotalStake/CaculateOTP';
import useToast from '../hook/useToast';
import * as loginApi from "../api/login/loginApi";
import tokenService from '../services/token';
import { useDispatch } from 'react-redux';
import { setUser } from "../store/slices/userSlice";
import { clearAlert } from '../store/slices/toastSlice';
import { PolygonBgLogo, logoKB, logoKB_2, soccer, sports_img, baseball, rugby, baketball, leaf_1, leaf_2, } from './imgExport';
import ToastGlobal from './ToastGlobal';
import { storeBillAcceptor } from '../api/billAcceptor/billAcceptorApi';

const OTPConfirm = () => {
  const pushToast = useToast();
  const dispatch = useDispatch();
  const [otp, setOTP] = useState<string[]>([])
  const clickNumber = (x: number | string) => {
    if (x === 'ok') {
      handleConfirm()
    } else {
      if (x === '.') {
        setOTP(otp.slice(0, -1))
      } else {
        if (x === 'del') {
          setOTP([])
        }
        else {
          if (otp.length < 6)
            setOTP((data: any) => [...data, x.toString()])
        }
      }

    }
  }

  const handleConfirm = async () => {
    dispatch(clearAlert())
    if (otp.length !== 6) {
      pushToast("Please enter the 6-digit verification code", "warn");
    } else {
      const ketqua = await loginApi.loginOTP(otp[0].toString() + otp[1].toString() + otp[2].toString() + otp[3].toString() + otp[4].toString() + otp[5].toString())
      if (ketqua?.success) {
        tokenService.setToken(ketqua.token)
        dispatch(
          setUser({
            userId: ketqua.data?.id ? ketqua.data?.id?.toString() : "0",
            presentMoney: ketqua.data?.current_money ? ketqua.data?.current_money?.toString() : "0",
            authCode: otp[0].toString() + otp[1].toString() + otp[2].toString() + otp[3].toString() + otp[4].toString() + otp[5].toString(),
            machineCode: ketqua.data?.seri_number ? ketqua.data?.seri_number : ""
          })
        )
        // navigator("/Soccer_V1");
        window.location.href = "/Soccer_V1"
      } else {
        pushToast(ketqua?.message, "warn");
      }
    }
  }


  const socketRef = useRef<WebSocket | null>(null);
  const [messages, setMessages] = useState<string[]>([]);

  let input_Message = "";
  useEffect(() => {
    socketRef.current = new WebSocket('ws://localhost:5056');
    socketRef.current.onopen = () => {
    };
    socketRef.current.onmessage = async (event) => {
      if (event.data instanceof Blob) {
        const text = await event.data.text();
        handleEvent(JSON.parse(text));
        setMessages((prevMessages) => [...prevMessages, text]);
      } else {
        setMessages((prevMessages) => [...prevMessages, event.data]);
      }
    };
    socketRef.current.onclose = () => {
    };
    socketRef.current.onerror = (error) => {
    };
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  // Hàm gửi tin nhắn
  const sendMessage = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(input_Message);
      input_Message = ''; // Reset input
    } else {
      console.log('WebSocket is not open');
    }
  };
  const handleEvent = async (message: { eventName: string; params?: any }) => {
    if (message.eventName === "insert_new_machine") {
      const { MachineName } = message.params || {};
      const ketQua = await storeBillAcceptor(MachineName, MachineName)
      if (ketQua.success) {
        const responseMessage = JSON.stringify({
          eventName: "success_insert_new_machine",
          params: {
            billAcceptorId: ketQua.data.id?.toString(),
            savedCode: ketQua.data.authCode?.toString()
          }
        });
        setTimeout(() => {
          input_Message = responseMessage;
          sendMessage();
        }, 5000);
      } else {
        pushToast("Some thing wrong. Please try later", "warn")
      }

    }
  };

  useEffect(() => {
    let isProcessing = false;
    const ws = new WebSocket("ws://localhost:5000");

    ws.onopen = async () => {
      console.log("Connected to WebSocket server");
      // Gửi lệnh yêu cầu server trả về dữ liệu liên tục
      ws.send("Start sending data continuously");
    };

    ws.onmessage = async (event) => {
      if (isProcessing) return;
      isProcessing = true;
      // console.log(`Received from server: ${event.data}`);
      if (JSON.parse(event.data).IsCreate === true) {
        const ketQua = await storeBillAcceptor(JSON.parse(event.data).Namepc, JSON.parse(event.data).Namepc)
        if (ketQua.success) {
          const data = {
            status: true,
            billAcceptorId: ketQua.data.id?.toString(),
            savedCode: ketQua.data.authCode?.toString()
          }
          ws.send(JSON.stringify(data));
        } else {
          pushToast("Some thing wrong. Please try later", "warn")
        }
      }
      // Reset flag sau khi gửi xong
      setTimeout(() => {
        isProcessing = false;
      }, 1000); // Thời gian chờ tùy chỉnh
    };
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Đóng kết nối khi component bị unmount
    return () => {
      ws.close();
    };
  }, []);


  return (
    <div className='h-full !w-full flex flex-col justify-between items-center bg-[#10131C]'>
      <div className="w-[728px] h-[728px] relative pl-4">
        <img src={PolygonBgLogo} alt="" />
        <img src={logoKB} alt="" className='absolute top-0 right-0' />
        <img src={logoKB_2} alt="" className='absolute top-[223px] right-[242px]' />
      </div>
      <div className='flex flex-col gap-4 w-[600px] items-center'>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col'>
            <span className='text-[#E4E7F1] text-[56px]  fw-[600]'>WELCOME BACK!</span>
            <span className='text-[#E4E7F1] text-[25px] fw-[500] uppercase'>Login and play now with <span className='text-[#D4B962]'>KingBet</span> </span>
          </div>
          <div className='flex gap-3'>
            <div className='rounded-xl w-[80px] h-[56px] flex flex-col items-center bg-gradient-A98F34'>
              <img src={soccer} alt="" />
              <span className='uppercase text-sm fw-[500] text-[#E4E7F1]'>soccer</span>
            </div>
            <div className='rounded-xl w-[80px] h-[56px] flex flex-col items-center bg-gradient-A98F34'>
              <img src={baketball} alt="" />
              <span className='uppercase text-sm fw-[500] text-[#E4E7F1]'>NBA</span>
            </div>
            <div className='rounded-xl w-[80px] h-[56px] flex flex-col items-center bg-gradient-A98F34'>
              <img src={rugby} alt="" />
              <span className='uppercase text-sm fw-[500] text-[#E4E7F1]'>NFL</span>
            </div>
            <div className='rounded-xl w-[80px] h-[56px] flex flex-col items-center bg-gradient-A98F34'>
              <img src={baseball} alt="" />
              <span className='uppercase text-sm fw-[500] text-[#E4E7F1]'>MLB</span>
            </div>
          </div>
        </div>
        <ToastGlobal />
        <Form className='flex flex-col'>
          <div className='flex justify-center gap-2'>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px]  !text-white !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[0] && otp[0] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[0]} />
            </Form.Item>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px] !text-white  !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[1] && otp[1] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[1]} />
            </Form.Item>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px] !text-white  !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[2] && otp[2] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[2]} />
            </Form.Item>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px] !text-white  !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[3] && otp[3] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[3]} />
            </Form.Item>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px]  !text-white !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[4] && otp[4] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[4]} />
            </Form.Item>
            <Form.Item className='' rules={[{ required: true }]}>
              <Input className={`!w-[50px] !h-[50px] !text-white  !rounded-[4px] text-center !text-[14px] !font-[700] ${otp[5] && otp[5] !== "" ? 'bg-otp' : ' border-otp-noactive'}`} value={otp[5]} />
            </Form.Item>
          </div>
          <div className='flex gap-1'>
            <CaculateOTP clickNumber={clickNumber} />
            <button type='submit' className='rounded-lg !h-[164px] !w-[52px] !text-sm pb-3 !font-[600] !text-[#131620] btn-confirm' onClick={() => handleConfirm()}>
              Go
            </button>
          </div>
        </Form>
        <div className='flex justify-center'>
          <div className='flex items-center gap-4'>
            <img src={leaf_1} alt="" />
            <span className='uppercase text-lg fw-[400] text-[#E4E7F1]'>copyright © kingbet 2024</span>
            <img src={leaf_2} alt="" />
          </div>
        </div>
      </div>
      <div>
        <img src={sports_img} alt="" />
      </div>

    </div>

  )
};

export default OTPConfirm;