import React, { useEffect, useRef, useState } from 'react';
import './styles.css'
import { Col, Flex, Layout, Row } from 'antd';
import HeadNavi from '../homepage/components/MainNavi/Head/HeadNavi';
import { itemMenu } from '../homepage/components/MainNavi/Tabs/TabsNaviV2';
import {
  bannerSleep,
  bannerMLB1,
  bannerMLB2,
  bannerNBA1,
  bannerNBA2,
  bannerNFL1,
  bannerNFL2,
  bannerSoccer1,
  bannerSoccer2,
  Dollar
} from '../../components/imgExport';
import useToast from '../../hook/useToast';
import ToastGlobal from '../../components/ToastGlobal';
import { unitPoint } from '../../until';
import { loadmoney } from '../../api/billAcceptor/billAcceptorApi';
import HeadRight from '../homepage/components/MainNavi/Head/HeadRight';

const { Header } = Layout;
const SleepMode: React.FC = () => {
  const pushToast = useToast();
  const [tournamentsSoccer, setTournamentsSoccer] = useState(itemMenu[0])
  const handelChooseTournamentsSoccer = (value: itemMenu) => {
    if (value.id !== 9) {
      setTournamentsSoccer(value)
      // gListGameByTournamentSoccer(value.id)
    }
    if (value.id === 0) {
      // gListOfGamesForTheHomepageSoccer()
    }
    window.location.href = value.href
    // navigator(value.href)
  }

  const handelChooseSport = (href: string) => {
    window.location.href = href
  }



  // const socketRef = useRef<WebSocket | null>(null);
  // const [messages, setMessages] = useState<string[]>([]);

  // let input_Message = "";
  // useEffect(() => {
  //   socketRef.current = new WebSocket('ws://localhost:5056');
  //   socketRef.current.onopen = () => {
  //   };
  //   socketRef.current.onmessage = async (event) => {
  //     if (event.data instanceof Blob) {
  //       const text = await event.data.text();
  //       handleEvent(JSON.parse(text));
  //       setMessages((prevMessages) => [...prevMessages, text]);
  //     } else {
  //       setMessages((prevMessages) => [...prevMessages, event.data]);
  //     }
  //   };
  //   socketRef.current.onclose = () => {
  //   };
  //   socketRef.current.onerror = (error) => {
  //   };
  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, []);


  // const handleEvent = async (message: { eventName: string; params?: any }) => {
  //   if (message.eventName === "update_money") {
  //     const { billAcceptorId, bankNote, timestamp } = message.params || {};
  //     const ketQua = await loadmoney(bankNote)
  //     if (ketQua.success) {
  //       pushToast(`You have successfully deposited ${unitPoint()}`, "success")
  //       setTimeout(() => {
  //         window.location.href = "/Soccer_V1"
  //       }, 1000)
  //     } else {
  //       pushToast("Some thing wrong. Please try later", "warn")
  //     }
  //   }
  // };


  return (
    <>
      <Flex gap="middle" wrap="wrap" className='h-full relative'>

        <div className="fixed z-[999] flex justify-center w-full pt-[100px]">
          <ToastGlobal />
        </div>
        <Layout className='w-full h-full pr-5'>

          <Header className='fixed z-[9999] top-0 w-full !p-0 !h-[56px] text-center headTab !bg-[#131620] flex items-center border-b border-[#202531] hidden-authcode'>
            <HeadNavi
              tournaments={tournamentsSoccer}
              setTournaments={handelChooseTournamentsSoccer}
            />
          </Header>
          <div className='fixed z-[9999] top-2 right-10 flex items-center'>
            <img src={Dollar} alt="" className='w-[30px] h-[30px]' />
            <HeadRight></HeadRight>
          </div>
          {/* <div className='fixed z-[9999] top-0 right-0 pt-2'>
            <HeadRight></HeadRight>

          </div> */}
          <div className='w-full h-[calc(100vh-56px)] mt-[56px] pt-[20px] flex flex-col items-center !bg-[#131620] gap-5'>
            <Row>
              <Col span={24}><img className='w-full' src={bannerSleep} alt="" /></Col>
            </Row>
            <Row>
              <Col span={12}><img className='w-full pr-2' src={bannerSoccer1} alt="" onClick={() => handelChooseSport("/Soccer_V1")} /></Col>
              <Col span={12}><img className='w-full pl-2' src={bannerNBA1} alt="" onClick={() => handelChooseSport("/NBA")} /></Col>
            </Row>
            <Row>
              <Col span={12}><img className='w-full pr-2' src={bannerNFL1} alt="" onClick={() => handelChooseSport("/NFL")} /></Col>
              <Col span={12}><img className='w-full pl-2' src={bannerMLB1} alt="" onClick={() => pushToast("Waiting for next season", "warn")} /></Col>
            </Row>
            <Row>
              <Col span={12}><img className='w-full pr-2' src={bannerSoccer2} alt="" onClick={() => handelChooseSport("/Soccer_V1")} /></Col>
              <Col span={12}><img className='w-full pl-2' src={bannerNBA2} alt="" onClick={() => handelChooseSport("/NBA")} /></Col>
            </Row>
            <Row>
              <Col span={12}><img className='w-full pr-2' src={bannerNFL2} alt="" onClick={() => handelChooseSport("/NFL")} /></Col>
              <Col span={12}><img className='w-full pl-2' src={bannerMLB2} alt="" onClick={() => pushToast("Waiting for next season", "warn")} /></Col>
            </Row>
          </div>
        </Layout>
      </Flex>


    </>
  )
}
export default SleepMode;