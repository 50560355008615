import React, { useEffect, useState } from 'react';
import HeadTabs from './HeadTabs';
import { baketball, baketballActive, baseball, baseballActive, NCAAF, rugby, rugbyActive, soccer, soccerActice } from '../../../../../components/imgExport';
import * as homePageApi from '../../../../../api/homePage/homePageApi'
import * as loginApi from '../../../../../api/login/loginApi';
export interface itemMenu {
  id: number;
  name: string;
  icon: string;
  iconActive?: string;
  href: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const itemMenu: itemMenu[] = [
  {
    id: 1,
    name: 'Basketball',
    href: '/NBA',
    icon: baketball,
    iconActive: baketball,
  },
  {
    id: 2,
    name: 'Football',
    href: '/NFL',
    icon: rugby,
    iconActive: rugby,
  },
  {
    id: 6,
    name: 'Baseball',
    href: '/MLB',
    icon: baseball,
    iconActive: baseball,
  },
  {
    id: 9,
    name: 'Soccer',
    href: '/Soccer',
    icon: soccer,
    iconActive: soccer,
  },

]
interface Props {
  tournaments: itemMenu
  setTournaments: (value: itemMenu) => void
}
const HeadNavi: React.FC<Props> = ({
  tournaments,
  setTournaments
}) => {
  const [menu, setMenu] = useState<itemMenu[]>([])
  const [OTPCode, setOTPCode] = useState<string>("")
  const checkSelect = (value: string) => {
    // console.log(value);
    return value === tournaments.name ? "!text-[#D4B962] " : "text-[#C1C9E1]"
  }

  const getAuthcodeByToken = async () => {
    const ketqua = await loginApi.getAuthcodeByToken()
    if (ketqua?.authCode) {
      setOTPCode(ketqua.authCode)
    }
  }

  // viewSettings
  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()

    if (ketqua?.success) {
      if (ketqua.data.navigation.SOC === 1) {
        setMenu([{
          id: 0,
          name: 'Soccer',
          href: '/Soccer_V1',
          icon: soccer,
          iconActive: soccerActice,
        }])
      }
      if (ketqua.data.navigation.NBA === 1) {
        setMenu((data: any) => [...data, {
          id: 1,
          name: 'Basketball',
          href: '/NBA',
          icon: baketball,
          iconActive: baketballActive,
        }])
      }
      if (ketqua.data.navigation.NFL === 1) {
        setMenu((data: any) => [...data, {
          id: 2,
          name: 'Football',
          href: '/NFL',
          icon: rugby,
          iconActive: rugbyActive,
        }])
      }
      if (ketqua.data.navigation.MLB === 1) {
        setMenu((data: any) => [...data, {
          id: 6,
          name: 'Baseball',
          href: '/MLB',
          icon: baseball,
          iconActive: baseballActive,
        }])
      }
      if (ketqua.data.navigation.NCAAF === 1) {
        setMenu((data: any) => [...data, {
          id: 3,
          name: 'NCAAF',
          href: '/NCAAF',
          icon: NCAAF,
          iconActive: NCAAF,
        }])
      }
      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 9,
      //     name: 'Soccer',
      //     href: '/Soccer',
      //     icon: Soccer
      //   }])
      // }
      // if (ketqua.data.navigation.NCAAF === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 3,
      //     name: 'NCAAF',
      //     href: '/NCAAF',
      //     icon: NCAAF
      //   }])
      // }


      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 10,
      //     name: 'Soccer',
      //     href: '/Soccer_V1',
      //     icon: Soccer
      //   }])
      // }

    }
  }

  useEffect(() => {
    viewSettings()
    getAuthcodeByToken()
  }, [])
  return (
    <div className="flex justify-between items-center w-full">
      <HeadTabs></HeadTabs>
      {/* <HeadRight></HeadRight> */}
      <div className='w-[1000px] h-[56px] flex justify-center items-center '>
        {menu.map((items) => (
          <div
            key={items.id}
            className=' h-[56px] flex justify-center items-center  px-6 border-r border-[#202531]'
            onClick={() => setTournaments(items)}
          >
            <div className='flex items-center gap-2 '>
              {items.name === tournaments.name ?
                <img src={items.iconActive} alt="" />
                :
                <img src={items.icon} alt="" />
              }

              <span className={`uppercase text-base fw-[500] ${checkSelect(items.name)}`}>{items.name}</span>
            </div>
          </div>
        ))}

      </div>

      <div className='text-[#222222] pr-5 authcode'>
        {OTPCode}
      </div>
    </div>
  )

}
export default HeadNavi;