/* eslint-disable @typescript-eslint/no-redeclare */
// import { Empty } from "antd"
import { ChooseBet, ChooseBetSoccer, InfoBetTeaserTMP, lineBettingSoccer } from "./api/betting/bettingApi"
import { InfoOdd } from "./api/homePage/homePageApi"
import { Game, InfoBetMGM, OptionMarket } from "./api/homePage/homePage_V2Api"
import * as homePage_V2Api from "./api/homePage/homePage_V2Api"
import EmptyGame from "./components/EmptyGame"

export const unit = () => {
    return "PT "
}

export const unitPoint = () => {
    return "point"
}

export const paserParams = (params: any, valueIsFail: any, allow?: CheckValueOptions) => {
    for (let i in params) {
        if (typeof (params[i]) === 'object') {
            params[i] = paserParams(params[i], valueIsFail, allow)
        } else {
            params[i] = parseValue(params[i], valueIsFail, allow)
        }
    }
    return params
}

export const parseValue = (value: any, valueIsFail: any, allow?: CheckValueOptions) => {

    if (!allow?.isNull) {
        if (value == null) {
            return valueIsFail;
        }
        if (Number.isNaN(value)) {
            return valueIsFail;
        }
    }

    if (!allow?.isEmpty) {
        if (['string', 'object'].includes(typeof (value)) && value.length === 0) {
            return valueIsFail;
        }
    }
    return value;
}
interface CheckValueOptions {
    isEmpty?: boolean,
    isNull?: boolean,
}

//chuyển text sang HTML
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConvertHTML = (item: any) => {
    return {
        __html: item,
    };
};

//ĐỊNH DẠNG GIÁ
export const formatPrice = (val: number | string | undefined) => {
    if (val !== undefined) {
        let v = val.toString();
        return v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "";
    }
};

//ĐỊNH DẠNG GIÁ
export const formatDateUiToSever = (val: string) => {
    if (val) {
        var arr = val.split("/");
        return arr[2] + "/" + arr[1] + "/" + arr[0]
    } else {
        return "";
    }
};
export const formatDateUiToSeverV2 = (val: string) => {
    if (val) {
        var arr = val.split("-");
        return arr[2] + "/" + arr[1] + "/" + arr[0]
    } else {
        return "";
    }
};

//check định dạng mail
export function validateEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//chuyển text sang HTML
export function convertHTML(item: any) {
    return {
        __html: item,
    };
};

export function arrayHandling(arr: string[], value: string) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    } else {
        arr.push(value);
    }
    return arr;
};

export const findSearchObject = (arr: any[], field: string, search: string) => {
    try {
        const filteredArray = arr.filter((e) => {
            const fieldValue = e[field];
            const regex = new RegExp(search, 'gi');
            return fieldValue.match(regex);
        });
        return filteredArray;
    } catch (error) {
        return []
    }

}

export function checkNoData(arr?: any[], className?: string) {
    if (!arr || arr.length === 0) {
        return (
            <div
                className={className}
            >
                {/* <Empty /> */}
                <EmptyGame />
            </div>
        );
    } else {
        return null;
    }
};

export const textToDate = (text: string) => {
    const parts = text?.split("-");
    if (parts?.length > 2) {
        const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const dateObject = new Date(isoDateString);
        return dateObject
    }
}

export function formatDateToISO(dateString: string) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
        const [day, month, year] = parts;
        // Lưu ý: Đảm bảo rằng giá trị của day, month, và year là số (integer)
        return `${year}-${month}-${day}`;
    }
    return ''; // Xử lý trường hợp không hợp lệ (optional)
}


export function isImageFile(file: any) {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    return validImageTypes.includes(file.type);
}
export function isAlphaNumeric(input: string): boolean {
    // Sử dụng biểu thức chính quy để kiểm tra chuỗi
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(input);
}

// export function getPiceWin(pice: number, bet: string) {
//     var xx = bet.split(" ").length
//     if (xx === 3) {
//         var odds = Math.abs(+bet.split(" ")[2])
//         return (pice + (pice * 100 / odds)).toFixed(2)
//     } else if (xx === 2) {
//         var odds = Math.abs(+bet.split(" ")[1])
//         return (pice + (pice * 100 / odds)).toFixed(2)
//     } else {
//         var odds = Math.abs(+bet.split(" ")[0])
//         return (pice + (pice * 100 / odds)).toFixed(2)
//     }

// }

export function getOddsParlay(bet: string) {
    var xx = bet.split(" ").length
    if (xx === 3) {
        var odds = Math.abs(+bet.split(" ")[2])
        return (odds).toFixed(2)
    } else if (xx === 2) {
        var odds = Math.abs(+bet.split(" ")[1])
        return (odds).toFixed(2)
    } else {
        var odds = Math.abs(+bet.split(" ")[0])
        return (odds).toFixed(2)
    }

}

export function getPiceWin(pice: number, bet: string) {
    var xx = bet.split(" ").length
    if (xx === 3) {
        var odds = Math.abs(+bet.split(" ")[2])
        // return (pice * odds).toFixed(2)
        return Math.round(pice * odds).toString()
    } else if (xx === 2) {
        var odds = Math.abs(+bet.split(" ")[1])
        // return (pice * odds).toFixed(2)
        return Math.round(pice * odds).toString()
    } else {
        var odds = Math.abs(+bet.split(" ")[0])
        // return (pice * odds).toFixed(2)
        return Math.round(pice * odds).toString()
    }

}

export function getPiceWinDecimal(price: number, bet: string, decimal?: string) {
    var xx = bet.split(" ").length
    var odds
    if (xx === 3) {
        if (decimal && decimal === "American") {
            if (Number(bet.split(" ")[2]) > 0) {
                odds = Math.abs(+bet.split(" ")[2]) / 100 + 1
            } else {

                odds = 100 / Math.abs(+bet.split(" ")[2]) + 1
            }
        } else {
            odds = Math.abs(+bet.split(" ")[2])
        }
        // return (price * odds).toFixed(2)
        return Math.round((price * odds)).toString()
    } else if (xx === 2) {
        if (decimal && decimal === "American") {
            if (Number(bet.split(" ")[1]) > 0) {
                odds = Math.abs(+bet.split(" ")[1]) / 100 + 1
            } else {

                odds = 100 / Math.abs(+bet.split(" ")[1]) + 1
            }
        } else {
            odds = Math.abs(+bet.split(" ")[1])
        }
        // return (price * odds).toFixed(2)
        return Math.round((price * odds)).toString()
    } else {

        if (decimal && decimal === "American") {
            if (Number(bet.split(" ")[0]) > 0) {
                odds = Math.abs(+bet.split(" ")[0]) / 100 + 1
            } else {

                odds = 100 / Math.abs(+bet.split(" ")[0]) + 1
            }
        } else {
            odds = Math.abs(+bet.split(" ")[0])
        }
        // return (price * odds).toFixed(2)
        return Math.round((price * odds)).toString()
    }

}

export function getPiceWinStright(pice: number, bet: string) {
    const coefficient = bet.split(" ")[1] || bet.split(" ")[0]
    // return +(pice * +coefficient).toFixed(2)
    return +(Math.round(pice * +coefficient)).toString()
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkConditionsBetTeaser = (listTeaserBetsSlipCheckTrue: InfoBetTeaserTMP[], listTeaserBetsSlip: InfoBetTeaserTMP[]) => {
    var isIsWarningBetTeaser = false
    var listBetTeaser: InfoBetTeaserTMP[]

    if (listTeaserBetsSlipCheckTrue.length < 2) {
        isIsWarningBetTeaser = true
        listBetTeaser = listTeaserBetsSlip
        return {
            isIsWarningBetTeaser,
            listTeaserBets: listBetTeaser
        };
    }
    // Kiểm tra xem có bất kỳ cặp game nào giống nhau hay không
    const gameSet: Set<string> = new Set(); // Sử dụng Set để kiểm tra các game duy nhất
    for (const item of listTeaserBetsSlipCheckTrue) {
        if (gameSet.has(item.game)) {
            listBetTeaser = checkWarningTeaser(item.game, listTeaserBetsSlipCheckTrue, listTeaserBetsSlip)
            isIsWarningBetTeaser = true
            return {
                isIsWarningBetTeaser,
                listTeaserBets: listBetTeaser
            };
        } else {
            gameSet.add(item.game);
        }
    }
    listBetTeaser = checkWarningTeaser("", listTeaserBetsSlipCheckTrue, listTeaserBetsSlip)
    isIsWarningBetTeaser = false
    // Nếu không có cặp game nào giống nhau và mảng có 2 hoặc nhiều hơn 2 phần tử
    return {
        isIsWarningBetTeaser,
        listTeaserBets: listBetTeaser
    }; // Trường hợp 2
}

const checkWarningTeaser = (game: string, listTeaserBetsSlipCheckTrue: InfoBetTeaserTMP[], listTeaserBetsSlip: InfoBetTeaserTMP[]) => {
    if (game) {
        const updatedList = listTeaserBetsSlipCheckTrue?.map(item => {
            if (item.game.includes(game)) {
                return { ...item, isWarning: true };
            }
            return item;
        });
        return updatedList
    } else {
        const updatedList = listTeaserBetsSlip.map(item => ({ ...item, isWarning: false }));
        return updatedList
    }

}

export const separateArrayGameLive = (value: InfoOdd[]) => {
    const overArray = value.filter(odd => odd.selection_line === "over");
    const underArray = value.filter(odd => odd.selection_line === "under");
    const otherArray = value.filter(odd => !["over", "under"].includes(odd.selection_line + ""));
    return { overArray, underArray, otherArray }

}

export const SeparateOnlineGameOddsByTypeOver = (value: InfoOdd[]) => {

    const infoOverSpread = value.filter(odd => odd.market === "point_spread")[0];
    const infoOverTotal = value.filter(odd => odd.market === "total_points")[0];
    const infoOverMoney = value.filter(odd => odd.market === "moneyline")[0];
    return { infoOverSpread, infoOverTotal, infoOverMoney }
}
export const SeparateOnlineGameOddsByTypeUnder = (value: InfoOdd[]) => {

    const infoUnderSpread = value.filter(odd => odd.market === "point_spread")[0];
    const infoUnderTotal = value.filter(odd => odd.market === "total_points")[0];
    const infoUnderMoney = value.filter(odd => odd.market === "moneyline")[0];
    return { infoUnderSpread, infoUnderTotal, infoUnderMoney }

}

export const checkSelectSoccer = (listChooseBet: ChooseBetSoccer[], team: string, lineBetting: lineBettingSoccer, game?: string) => {
    let isDuplicate = false;
    if (team.length === 0) {
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === "" && bet.game === game && bet.lineBetting === lineBetting
        );
    } else { // Kiểm tra team không phải undefined
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === team && bet.lineBetting === lineBetting
        );
    }
    return isDuplicate ? "bg-D4B962" : "";
};

export const checkSelectSoccer_V2 = (order: number, orderId: string | number = 0, id: string | number = 0, listChooseBet: ChooseBetSoccer[], team: string, lineBetting: lineBettingSoccer, game?: string) => {
    let isDuplicate = false;
    // if (orderId == 0) {
    //     if (team.length === 0) {
    //         isDuplicate = listChooseBet.some(
    //             (bet) => bet.teamChoose === "" && bet.game === game && bet.lineBetting === lineBetting && bet.order === order && bet.id === id
    //         );
    //     } else { // Kiểm tra team không phải undefined
    //         isDuplicate = listChooseBet.some(
    //             (bet) => bet.teamChoose === team && bet.lineBetting === lineBetting && bet.order === order && bet.id === id
    //         );
    //     }
    //     return isDuplicate ? "!bg-[#D4B962]" : "";
    // } else {
    //     if (team.length === 0) {
    //         isDuplicate = listChooseBet.some(
    //             (bet) => bet.teamChoose === "" && bet.game === game && bet.lineBetting === lineBetting && bet.order === order && bet.id === id && bet.orderId === orderId
    //         );
    //     } else { // Kiểm tra team không phải undefined
    //         isDuplicate = listChooseBet.some(
    //             (bet) => bet.teamChoose === team && bet.lineBetting === lineBetting && bet.order === order && bet.id === id
    //         );
    //     }
    //     return isDuplicate ? "!bg-[#D4B962]" : "";
    // }
    if (team.length === 0) {
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === "" && bet.game === game && bet.lineBetting === lineBetting && bet.order === order && bet.id === id && bet.orderId === orderId
        );
    } else { // Kiểm tra team không phải undefined
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === team && bet.lineBetting === lineBetting && bet.order === order && bet.id === id && bet.orderId === orderId
        );
    }
    return isDuplicate ? "!bg-[#D4B962]" : "";

};

export const checkSelectSoccerNew_V2 = (order: number, orderId: number = 0, id: string | number = 0, listChooseBet: ChooseBetSoccer[], team: string, lineBetting: lineBettingSoccer, game?: string) => {
    let isDuplicate = false;
    if (team.length === 0) {
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === "" && bet.game === game && bet.id === id
        );
    } else { // Kiểm tra team không phải undefined
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === team && bet.id === id
        );
    }
    return isDuplicate ? "!bg-[#D4B962]" : "";

};

export const checkSelectSoccerMoreTabNew_V2 = (option: any, order: number, orderId: number = 0, id: string | number = 0, listChooseBet: ChooseBetSoccer[], team: string, lineBetting: lineBettingSoccer, game?: string) => {
    let isDuplicate = false;
    if (team.length === 0) {
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === "" && bet.game === game && bet.id === id && bet.option === option
        );
    } else { // Kiểm tra team không phải undefined
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === team && bet.id === id && bet.option === option
        );
    }
    return isDuplicate ? "!bg-[#D4B962]" : "";

};

export const checkSelectMoreTabSoccer_V2 = (order: number, orderId: number = 0, id: string | number = 0, listChooseBet: ChooseBetSoccer[], team: string, lineBetting: lineBettingSoccer, game?: string) => {
    let isDuplicate = false;
    if (team.length === 0) {
        isDuplicate = listChooseBet.some(
            (bet) => bet.teamChoose === "" && bet.orderId === orderId
        );
    } else { // Kiểm tra team không phải undefined
        isDuplicate = listChooseBet.some(
            (bet) => bet.orderId === orderId
        );
    }
    return isDuplicate ? "!bg-[#D4B962]" : "";

};

export const covertDataOptionMarkets = (optionMarkets: OptionMarket[]) => {
    let spread: OptionMarket, totals: OptionMarket, moneyLine: OptionMarket;
    let FHspread: OptionMarket, FHtotals: OptionMarket, FHmoneyLine: OptionMarket;
    let spread5Innings: OptionMarket, totals5Innings: OptionMarket, moneyLine5Innings: OptionMarket;

    spread = optionMarkets.filter((market) => market !== null && market?.name?.value === "Spread")[0]
    totals = optionMarkets.filter((market) => market !== null && market?.name?.value === "Totals")[0]
    moneyLine = optionMarkets.filter((market) => market !== null && market?.name?.value === "Money Line")[0]
    FHspread = optionMarkets.filter((market) => market !== null && market?.name?.value === "First half spread")[0]
    FHtotals = optionMarkets.filter((market) => market !== null && market?.name?.value === "1st Half Totals")[0]
    FHmoneyLine = optionMarkets.filter((market) => market !== null && market?.name?.value === "First half money line")[0]

    spread5Innings = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Spread")[0]
    totals5Innings = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Total runs")[0]
    moneyLine5Innings = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Money line")[0]

    return { spread, totals, moneyLine, FHspread, FHtotals, FHmoneyLine, spread5Innings, totals5Innings, moneyLine5Innings }
};

export const covertDataOptionMarketsOld = (optionMarkets: OptionMarket[]) => {
    let spreadOld: OptionMarket, totalsOld: OptionMarket, moneyLineOld: OptionMarket;
    let FHspreadOld: OptionMarket, FHtotalsOld: OptionMarket, FHmoneyLineOld: OptionMarket;
    let spread5InningsOld: OptionMarket, totals5InningsOld: OptionMarket, moneyLine5InningsOld: OptionMarket;

    spreadOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "Spread")[0]
    totalsOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "Totals")[0]
    moneyLineOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "Money Line")[0]

    FHspreadOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "First half spread")[0]
    FHtotalsOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "1st Half Totals")[0]
    FHmoneyLineOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "First half money line")[0]

    spread5InningsOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Spread")[0]
    totals5InningsOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Total runs")[0]
    moneyLine5InningsOld = optionMarkets.filter((market) => market !== null && market?.name?.value === "First 5 innings: Money line")[0]

    return { spreadOld, totalsOld, moneyLineOld, FHspreadOld, FHtotalsOld, FHmoneyLineOld, spread5InningsOld, totals5InningsOld, moneyLine5InningsOld }
};

export const covertDataGames = (optionMarkets: Game[]) => {
    let spread: Game, totals: Game, moneyLine: Game;
    let spread5Innings: Game, totals5Innings: Game, moneyLine5Innings: Game;

    spread = optionMarkets.filter((game) => game !== null && game?.name?.value === "Spread")[0]
    totals = optionMarkets.filter((game) => game !== null && game?.name?.value === "Totals")[0]
    moneyLine = optionMarkets.filter((game) => game !== null && game?.name?.value === "Money Line")[0]

    spread5Innings = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Spread")[0]
    totals5Innings = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Total runs")[0]
    moneyLine5Innings = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Money line")[0]

    return { spread, totals, moneyLine, spread5Innings, totals5Innings, moneyLine5Innings }
};

export const covertDataGamesOld = (optionMarkets: Game[]) => {
    let spreadOld: Game, totalsOld: Game, moneyLineOld: Game;
    let FHspreadOld: Game, FHtotalsOld: Game, FHmoneyLineOld: Game;
    let spread5InningsOld: Game, totals5InningsOld: Game, moneyLine5InningsOld: Game;

    spreadOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "Spread")[0]
    totalsOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "Totals")[0]
    moneyLineOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "Money Line")[0]

    FHspreadOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "First half spread")[0]
    FHtotalsOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "1st Half Totals")[0]
    FHmoneyLineOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "First half money line")[0]

    spread5InningsOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Spread")[0]
    totals5InningsOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Total runs")[0]
    moneyLine5InningsOld = optionMarkets.filter((game) => game !== null && game?.name?.value === "First 5 innings: Money line")[0]

    return { spreadOld, totalsOld, moneyLineOld, FHspreadOld, FHtotalsOld, FHmoneyLineOld, spread5InningsOld, totals5InningsOld, moneyLine5InningsOld }
};


export const fomatDataHomeBetMGM = (data: InfoBetMGM[]) => {
    const competitions: { [key: string]: InfoBetMGM[] } = {};

    // Duyệt qua mỗi phần tử trong mảng data
    data.forEach(item => {
        // Kiểm tra xem competition có tồn tại không và có thuộc tính name không
        if (item.competition?.name) {
            const competitionName = item.competition.name.value;

            // Kiểm tra xem mảng con cho cuộc thi đã tồn tại trong đối tượng competitions chưa
            if (!competitions[competitionName]) {
                // Nếu chưa tồn tại, tạo một mảng mới
                competitions[competitionName] = [];
            }

            // Thêm phần tử vào mảng con của cuộc thi tương ứng
            competitions[competitionName].push(item);
        }
    });


    return competitions
};

export const getChangeColor = (oldNew: number, old: number) => {
    if (oldNew && old) {
        switch (true) {
            case oldNew > old:
                return "!border-green-600"
            case oldNew < old:
                return "!border-red-600"

            default:
                return ""
        }
    } else {
        return ""
    }
}

export const getChangeColor_V2 = (oldNew: number, old: number, callback?: () => void) => {
    let result = "";
    if (oldNew && old) {
        switch (true) {
            case oldNew > old:
                result = "!border-green-600";
                break;
            case oldNew < old:
                result = "!border-red-600";
                break;
            default:
                result = "";
        }
    }
    if (result && callback) {

        // Gọi hàm callback nếu được cung cấp
        callback();
    }
    return result;
}

export const getChangeClass_V3 = (
    oldNew?: number, old?: number, dateFilterSoccer?: string, listDateFilterSoccer?: homePage_V2Api.infoListDate[]
) => {
    let result = "";
    if (oldNew) {
        if ((dateFilterSoccer && listDateFilterSoccer && listDateFilterSoccer.length && listDateFilterSoccer?.[0] && dateFilterSoccer === listDateFilterSoccer?.[0]?.value) || (!dateFilterSoccer && !listDateFilterSoccer)) {

            if (oldNew && old) {
                switch (true) {
                    case oldNew > old:
                        result = "absolute top-0 right-0 rotate-[-45deg] text-[#ACE433]";
                        break;
                    case oldNew < old:
                        result = "absolute bottom-0 right-0 rotate-45 text-[#BB271A]";
                        break;
                    default:
                        result = "hidden";
                }
            } else {
                result = "hidden";
            }

            return result;
        } else {
            return "hidden";
        }
    } else {
        return result;
    }
}

export const getSuspended = (
    teamChoose?: string, lineBetting?: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", listChooseBet?: ChooseBet[], callback?: () => void
) => {
    var isDuplicate: any
    if (teamChoose) {
        isDuplicate = listChooseBet && listChooseBet.some(
            (bet) => bet.teamChoose.indexOf(teamChoose) !== -1 && bet.lineBetting === lineBetting
            // (bet) => bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet.dateEvent === startDate
        );
    }
    if (isDuplicate && callback) {
        callback();
    }
    return " ";
}


export const getChangeColor_V3 = (
    oldNew?: number, old?: number, teamChoose?: string, lineBetting?: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", startDate?: string, listChooseBet?: ChooseBet[], callback?: () => void
) => {
    let result = "", result1 = "";
    if (oldNew && old) {
        switch (true) {
            case oldNew > old:
                result = "border !border-[#33E49A] key-frame-light-green ";
                break;
            case oldNew < old:
                result = "border !border-[#FF4A59] key-frame-light-red ";
                break;
            default:
                result = "";
        }
    }
    var isDuplicate: any
    if (teamChoose) {
        isDuplicate = listChooseBet && listChooseBet.some(
            (bet) => bet.teamChoose.indexOf(teamChoose) !== -1 && bet.lineBetting === lineBetting
            // (bet) => bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet.dateEvent === startDate
        );
    }

    result1 = isDuplicate ? "!bg-[#D4B962]" : ""
    if (result && result1 && callback) {
        callback();
    }
    return result + " " + result1;
}

export const getChangeColorSoccer_V3 = (
    oldNew?: number, old?: number, callback?: () => void, dateFilterSoccer?: string, listDateFilterSoccer?: homePage_V2Api.infoListDate[]
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (oldNew) {
        if ((dateFilterSoccer && listDateFilterSoccer && listDateFilterSoccer.length && listDateFilterSoccer?.[0] && listDateFilterSoccer?.[0]?.value && dateFilterSoccer === listDateFilterSoccer?.[0]?.value) || (!dateFilterSoccer && !listDateFilterSoccer)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let result = "", result1 = "";
            if (oldNew && old && old !== 0 && oldNew !== 0) {
                switch (true) {
                    case oldNew > old:
                        result = "!border-[#33E49A] key-frame-light-green ";
                        break;
                    case oldNew < old:
                        result = "!border-[#FF4A59] key-frame-light-red ";
                        break;
                    default:
                        result = "";
                }
            }
            if (result && callback) {
                callback();
            }
            return result
        } else {
            return "";
        }
    } else {
        return ""
    }
}

export const getChangeColorText = (oldNew?: number, old?: number) => {
    let result = "";
    if (oldNew && old) {
        switch (true) {
            case oldNew > old:
                result = "text-green";
                break;
            case oldNew < old:
                result = "text-red";
                break;
            default:
                result = "text-black";
        }
    }
    return result;
}
