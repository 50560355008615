// export const API_URL_SOCKET = "http://192.168.1.129:5005/";
// export const API_URL = "http://api1.kingbets360.com:5002/";
export const API_URL_SOCKET = "http://124.158.13.114:5005/";
export const API_URL = "http://124.158.13.115:5002/";
export const API_URL_SOCCER = "http://api.kingbets360.com:5001/";
export const API_URL_BILL_ACCEPTOR = "http://api.kingbets360.com:5005/";
// export const API_URL = "http://api.kingbets360.bdata.link:5003/";

export const ICON_COUNTRY = "https://cms.kingbets360.com/uploads/iconCountry/";
export const ICON_GAMME = "https://cms.kingbets360.com/uploads/iconGame/";
export const URL_BANNER = "https://cms.kingbets360.com/uploads/banner/";

export const ICON_TEAM = "https://media.itsfogo.com/media/upload/prod/participants/";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    process: {
        env: {
            API_URL,
            ICON_COUNTRY,
            ICON_GAMME
        },
    },
};