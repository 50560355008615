import { useEffect, useMemo, useState } from "react";
import { InfoAddStrightSoccerTmp, InfoParlaySoccerTmp } from "../../../../api/betting/bettingApi";
import CaculateComponent from "./Caculate";
import { formatPrice, unit } from "../../../../until";
import useToast from "../../../../hook/useToast";
import useThrottle from "../../../../hook/useThrottle";
import { warningBetslip } from "../../../../components/imgExport";
import ToastGlobal from "../../../../components/ToastGlobal";


interface TotalStakeProps {
    keyBetTabs: string,
    numberStake: number,

    isWarningBetParlay?: boolean
    textWarningBetParlaySoccer?: string
    showTotalStake: boolean,
    showTotalBet: boolean,

    totalOddsParlay?: number
    totalTParlay?: number
    totalAmountParlay?: number
    listAddStrightBetsSlip: InfoAddStrightSoccerTmp[]
    listParlayBetsSlip?: InfoParlaySoccerTmp[]

    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    showHideTotalStake: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBetStright: () => void
    handleCheckBetParlay?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    hiddenBetPlaceSoccer?: boolean,
}

function isNumber(value?: string | number): boolean {
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
}

const TotalStakeComponentV2 = ({
    keyBetTabs, numberStake, isWarningBetParlay, textWarningBetParlaySoccer,
    showTotalBet, showTotalStake,
    totalOddsParlay, totalTParlay, totalAmountParlay,
    listAddStrightBetsSlip, listParlayBetsSlip,
    handleClick, showHideTotalStake, handleNumberClick, handleDeleteClick, handleCheckBetStright, handleCheckBetParlay, handleCheckQuantityMoneyStraight,
    hiddenBetPlaceSoccer
}: TotalStakeProps) => {

    const pushToast = useToast();


    const [totalBetStright, setTotalBetStright] = useState(0)
    const [totalWinStright, setTotalWinStright] = useState(0)
    const [totalBetParlay, setTotalBetParlay] = useState(0)
    const [totalAmountStright, setTotalAmountStright] = useState(0)
    const [warningParlay, setWarningParlay] = useState<boolean>(false)

    const checkBetParlay = useThrottle(() => {
        if (isWarningBetParlay)
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
        else {
            handleCheckBetParlay?.()
        }
    }, 1000)


    const handleSuccess = () => {
        showHideTotalStake()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }

    useMemo(() => {
        const totalAmountTmp = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmountStright(totalAmountTmp)
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            var coefficient: any
            if (currentItem.scoreChoose.split(" ").length <= 2) {
                coefficient = isNumber(currentItem.scoreChoose.split(" ")[1]) ? currentItem.scoreChoose.split(" ")[1] : 0 || currentItem.scoreChoose.split(" ")[0]
            } else {
                coefficient = isNumber(currentItem.scoreChoose.split(" ")[currentItem.scoreChoose.split(" ").length - 1]) ? currentItem.scoreChoose.split(" ")[currentItem.scoreChoose.split(" ").length - 1] : 0 || currentItem.scoreChoose.split(" ")[0]
            }
            return currentItem.isCheck ? (accumulator + Number(currentItem.amount * (+coefficient))) : +accumulator;
        }, 0);
        setTotalWinStright(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listAddStrightBetsSlip.filter((item) => item.isCheck);
        setTotalBetStright(filteredArray.length)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        // eslint-disable-next-line no-mixed-operators
        const filteredArray = listParlayBetsSlip && listParlayBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetParlay(filteredArray.length)

    }, [listParlayBetsSlip])

    useEffect(() => {
        setWarningParlay(false)
        if (totalBetParlay >= 3 && keyBetTabs === "2") {
            setTimeout(() => { setWarningParlay(isWarningBetParlay ?? false) }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWarningBetParlay, totalBetParlay])

    return (
        <>
            <div
                className={`border-warning-betslip rounded-[6px] p-1 bg-[#39372E] flex justify-start items-center ${totalBetParlay >= 3 && isWarningBetParlay && warningParlay && keyBetTabs === "2" ? "" : "!hidden"} `}
            // hidden={(!isWarningBetParlay && keyBetTabs === "2") || keyBetTabs === "1" || (totalBetParlay < 2 && keyBetTabs === "2")}
            >
                <img src={warningBetslip} alt="" />
                <span className="pl-[6px] text-left text-[#D4B962] text-[13px] font-[500]">{textWarningBetParlaySoccer ? textWarningBetParlaySoccer : "Parlay cannot be placed as some of your legs cannot be combined"}</span>
            </div>
            <div
                className={`border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] flex justify-start items-center ${(totalBetParlay > 2 && keyBetTabs === "2") || keyBetTabs === "1" ? "!hidden" : ""}`}
            >
                <img src={warningBetslip} alt="" />
                <span className="pl-[6px] text-left text-[#D4B962]">3+ selections needed for a Parlay.</span>
            </div>
            <div
                className={`totalStake `}
                hidden={showTotalBet}
            >


                <div className='flex justify-between items-center h-14'>
                    <span className='text-white text-[16px]'>Stake per bet</span>
                    {
                        keyBetTabs === "1" &&
                        <span className='font-bold text-[16px] h-[32px] stakeButton text-white' onClick={showHideTotalStake}>
                            {/* text-[#666E97] */}
                            {numberStake === 0 ? '' : (<span className="">{unit()}</span>)}
                            {numberStake === 0 ? 'Stake per bet' : `${numberStake} `}
                        </span>
                    }
                    {
                        keyBetTabs === "2" &&
                        <span className='font-bold text-[16px] h-[32px] stakeButton text-white' onClick={showHideTotalStake}>
                            {/* text-[#666E97] */}
                            {totalAmountParlay === 0 ? '' : (<span className="">{unit()}</span>)}
                            {totalAmountParlay === 0 ? 'Stake per bet' : `${totalAmountParlay} `}
                        </span>
                    }
                </div>
                <CaculateComponent
                    showTotalStake={showTotalStake}
                    handleClick={handleClick}
                    handleNumberClick={handleNumberClick}
                    handleDeleteClick={handleDeleteClick}
                    handleSuccess={handleSuccess}
                />
                {/* <div className={`${hiddenBetPlaceSoccer ? 'hidden' : ''}`}> */}
                <div>
                    {
                        keyBetTabs === "1" &&
                        <>
                            <span
                                className={`flex justify-start pb-5 text-white ${totalBetStright > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetStright} Bets
                            </span>
                            <div className="flex justify-between">
                                <div className='flex flex-col justify-between pb-5'>
                                    <span className="text-white text-base">Total stake</span>
                                    <span className='text-white font-bold text-base'>{unit()}{formatPrice(totalAmountStright || 0)}</span>
                                </div>
                                <div className='flex  flex-col justify-between pb-5'>
                                    <span className="text-white text-base">Total win</span>
                                    <span className='text-[#33E49A] font-bold text-base'>{totalWinStright === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(totalWinStright) || 0)}`}</span>
                                </div>

                            </div>

                            <div className="place-bet-soccer">
                                <button className={`font-bold rounded-[2px] uppercase w-full py-[10px] bg-[#D4B962] text-black`} onClick={handleCheckBetStright}>
                                    Place bet
                                </button>
                            </div>
                        </>

                    }
                    {
                        keyBetTabs === "2" &&
                        <>
                            <span
                                className={`flex justify-start pb-5 text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span>
                            <div>
                                <div className="flex justify-between">
                                    <div className='flex flex-col justify-between pb-5'>
                                        <span className="text-white">Total odds</span>
                                        <span className='text-white'>{formatPrice((totalOddsParlay || 0).toFixed(2))}</span>
                                    </div>
                                    <div className='flex flex-col justify-between pb-5'>
                                        <span className="text-white">Total win</span>
                                        <span className='text-[#33E49A]'>{totalAmountParlay === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(((totalAmountParlay || 0) + (totalAmountParlay || 0) * ((totalOddsParlay || 1) - 1 || 0))) || 0)}`}</span>
                                        {/* <span className='text-[#33E49A]'>{totalAmountParlay === 0 ? `${unit()}0` : `${unit()}${formatPrice(Math.round(((totalAmountParlay || 0) + (totalAmountParlay || 0) * ((totalOddsParlay || 1) - 1 || 0) * (totalTParlay || 1))) || 0)}`}</span> */}
                                    </div>
                                </div>

                                <div
                                >
                                    {!isWarningBetParlay && listParlayBetsSlip && listParlayBetsSlip.length >= 3 && (
                                        // <Button
                                        //     className="!bg-[#D4B962] w-[100%] rounded-md font-bold"
                                        //     type="primary"
                                        //     onClick={checkBetParlay}
                                        // >
                                        //     Bet place
                                        // </Button>
                                        <button className={`font-bold rounded-[2px] uppercase w-full py-[10px] bg-[#D4B962] text-black`} onClick={checkBetParlay}>
                                            Place bet
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div >
            <div className="w-[320px]">
                <ToastGlobal />
            </div>
        </>
    )
}
export default TotalStakeComponentV2