/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd"
// import { useSelector } from "react-redux"
// import { selectorUser } from "../../store/slices/userSlice"
import { loadingConfirmbet } from "../imgExport";
import { useEffect, useState } from "react";

interface ModalBetsProps {
  showModal: boolean,
  setShowModal: (value: boolean) => void
  sleepMode: () => void
}
var interval: any
const ModalConfirmSleep = ({ showModal, setShowModal, sleepMode }: ModalBetsProps) => {
  // const { userId, presentMoney } = useSelector(selectorUser);
  const [countdown, setCountdown] = useState<number>(20)
  useEffect(() => {
    setCountdown(20)
    if (showModal) {
      var time = 20
      interval = setInterval(() => {
        if (time > 0) {
          time -= 1
          setCountdown(time)
        } else {
          clearInterval(interval)
          sleepMode()
        }
      }, 1000);
    } else {
      clearInterval(interval)
    }
  }, [showModal])
  return (
    <Modal
      className='canPlayModal cancelConfirmBet flex justify-center'
      title={<div className="flex justify-center items-center relative"><p className="absolute text-[#f4f4f5]">{countdown ? countdown : ""}</p><img className="rotating" src={loadingConfirmbet} alt="" /></div>}
      // title={status === 1 ? <Spin tip="Loading" size="large" className='size-9' /> : <img src={cancelConfirmBet} alt="" />}
      // open={true}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <p className="text-center !text-[18px] text-[#D4B962] !pb-1">Session ending</p>
      <p className="!text-[14px] !pb-0">Touch anywhere to continue</p>
    </Modal>
  )
}
export default ModalConfirmSleep